import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Label = ({ variant, colour, content, className }) => {
    const classes = ['c-label', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div
            className={concatenatedClasses}
            style={
                variant === 'outline'
                    ? {
                          color: colour,
                          backgroundColor: 'white',
                          border: `2px solid ${colour}`
                      }
                    : { backgroundColor: colour }
            }
        >
            {content}
        </div>
    )
}

Label.propTypes = {
    /**
     * Label variant
     */
    variant: PropTypes.oneOf(['standard', 'outline']),
    /**
     * Label colour
     */
    colour: PropTypes.string,
    /**
     * Label text
     */
    content: PropTypes.string.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

Label.defaultProps = {
    variant: 'standard',
    colour: '#000000',
    content: 'Label text'
}

export default Label
